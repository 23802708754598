import { gql } from '@apollo/client';

export const MemorabiliaFields = gql`
  fragment MemorabiliaFields on Memorabilia {
    id
    comment
    description
    fulfillment
    images {
      id
      isMainImage
      memorabiliaId
      key
      url
    }
    hashtags {
      id
      name
    }
    memorabiliaProductType
    numberOfUnits
    requestedPrice
    status
    storeId
    title
    createdAt
    deletedAt
    price
    slug
    store {
      id
      role
      slug
      storeDetails {
        id
        storeName
        avatarURL
      }
    }
    images {
      id
      isMainImage
      memorabiliaId

      key
      url
    }
    isForAuction
    onAuctionFrom
    onAuctionTo
    auctions {
      id
      startsAt
      expiresAt
      currentPrice
      status
      startPrice
      isShippingPaid
      reservedBids {
        id
        actualAmount
        amount
        customerId
        paidAt
        shippingCost
      }
    }
  }
`;

export const MemorabiliaFieldsAuthorized = gql`
  fragment MemorabiliaFieldsAuthorized on Memorabilia {
    id
    comment
    description
    fulfillment
    images {
      id
      isMainImage
      memorabiliaId
      key
      url
    }
    memorabiliaProductType
    numberOfUnits
    requestedPrice
    hashtags {
      id
      name
    }
    status
    storeId
    title
    createdAt
    deletedAt
    price
    slug
    shipmentCategory
    customsDescription
    store {
      id
      role
      slug
      storeDetails {
        id
        storeName
        avatarURL
      }
    }
    images {
      id
      isMainImage
      memorabiliaId
      url
      key
    }
    originAddress {
      id
    }
    isForAuction
    onAuctionFrom
    onAuctionTo
    auctions {
      id
      startsAt
      expiresAt
      currentPrice
      status
      startPrice
      isShippingPaid
      reservedBids {
        id
        actualAmount
        amount
        customerId
        paidAt
        shippingCost
      }
    }
    autoSocialMediaPackStatus
    autoSocialPackImages {
      id
      status
      url
    }
  }
`;
