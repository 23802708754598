import { useState } from 'react';
// Components
import VideoControlButton from 'ui/VideoPlayer/components/VideoControlButton/VideoControlButton';

import styles from './CameraOption.module.scss';

type CameraOptionProps = {
  onToggle: () => void;
};

const CameraOption = ({ onToggle }: CameraOptionProps) => {
  // to avoid the passing of initial value let's assume that camera will be enabled by default
  const [enable, setEnable] = useState<boolean>(true);

  const handleButtonClick = () => {
    onToggle();
    setEnable((prev) => !prev);
  };

  return (
    <div className={styles.root}>
      <VideoControlButton
        icon={enable ? 'camera' : 'disabled-camera'}
        title={enable ? 'Disable camera' : 'Enable camera'}
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default CameraOption;
