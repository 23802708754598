import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
} from '@apollo/client';
// Api
import { GET_MEMORABILIA_MINIMAL_INFO } from 'api/memorabilia/queries';
// Types
import {
  GetMemorabiliaMinimalInfo,
  GetMemorabiliaMinimalInfoVariables,
} from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
import { MemorabiliaFilterInput } from 'api/graphql-global-types';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type UseGetAthleteMemorabiliaOptions = {
  options?: BasicQueryOptions;
  variables?: MemorabiliaFilterInput;
  storeId: string;
  skip?: boolean;
};

export const useGetAthleteMemorabilia = (
  options?: UseGetAthleteMemorabiliaOptions
): QueryResult<
  GetMemorabiliaMinimalInfo,
  GetMemorabiliaMinimalInfoVariables
> => {
  return useQuery<
    GetMemorabiliaMinimalInfo,
    GetMemorabiliaMinimalInfoVariables
  >(GET_MEMORABILIA_MINIMAL_INFO, {
    variables: {
      input: {
        ...options?.variables,
        ...(options?.storeId && { storeIds: [options.storeId] }),
      },
    },
    skip: options?.skip || !options?.storeId,
    fetchPolicy: 'cache-and-network',
    ...options?.options,
  });
};
