import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const computeDuration = (durationTime: number): string => {
  const duration = Number(durationTime).toFixed(2);
  const seconds = Number(duration.substring(0, duration.indexOf('.') + 1));
  const time = dayjs.duration(seconds, 'seconds');

  return time.hours() > 0 ? time.format('HH:mm:ss') : time.format('mm:ss');
};
