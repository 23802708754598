import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Tooltip, IPosition } from 'react-tooltip';
// Helpers
import { computeDuration } from 'helpers/time';
// Styles
import 'react-tooltip/dist/react-tooltip.css';
import styles from './VideoSeek.module.scss';

const RANGE_THUMB_WIDTH = 10;

type VideoSeekProps = {
  buffered: { start: number; end: number }[];
  time: number;
  duration: number;
  onSeek: (time: number) => void;
};

const VideoSeek = ({ buffered, time, duration, onSeek }: VideoSeekProps) => {
  const seekInputRef = useRef<HTMLInputElement | null>(null);
  const [hoveredTime, setHoveredTime] = useState<string | null>(null);
  const tooltipLeftPositionRef = useRef(0);

  const seekValue = (duration ? time / (duration / 100) : 0).toFixed(5);
  const bufferedValue =
    buffered.length > 0
      ? buffered[buffered.length - 1].end / (duration / 100)
      : 0;

  const setSeekProperty = (value: number | string) => {
    if (seekInputRef.current) {
      seekInputRef.current.style.setProperty(
        '--seek-before-width',
        `${value}%`
      );
      seekInputRef.current.style.setProperty(
        '--seek-thumb-width',
        `${RANGE_THUMB_WIDTH}px`
      );
    }
  };

  useEffect(() => {
    setSeekProperty(seekValue);

    if (seekInputRef.current) {
      seekInputRef.current.value = `${seekValue}`;
    }
  }, [seekValue]);

  const handleSeekInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seekInputValue = Number(e.target.value);
    const seekTime = (duration / 100) * seekInputValue;

    setSeekProperty(seekInputValue);
    onSeek(seekTime);
  };

  const handleOnMouseMove: MouseEventHandler<HTMLInputElement> = (e) => {
    if (seekInputRef.current) {
      const hoveredSectionValue =
        ((e.nativeEvent.offsetX - RANGE_THUMB_WIDTH / 3) /
          (seekInputRef.current.clientWidth - RANGE_THUMB_WIDTH)) *
        parseFloat(seekInputRef.current.max);
      const hoveredTime = (duration / 100) * hoveredSectionValue;

      setHoveredTime(computeDuration(hoveredTime));
      tooltipLeftPositionRef.current = e.clientX;
    }
  };

  const handleOnMouseLeave = () => {
    setHoveredTime(null);
  };

  const calculateTooltipYPosition = (): IPosition => {
    return {
      x: tooltipLeftPositionRef.current || 0,
      y: seekInputRef.current?.getBoundingClientRect().top || 0,
    };
  };

  return (
    <>
      <div className={styles.root} id="hovered-time">
        <div className={styles.line} />
        <div
          className={styles.buffered}
          style={{
            width: `${bufferedValue}%`,
          }}
        />
        <input
          aria-label="video seek"
          type="range"
          min="0"
          step="0.1"
          max="100"
          onChange={handleSeekInputChange}
          onMouseMove={handleOnMouseMove}
          onMouseLeave={handleOnMouseLeave}
          className={styles.seek}
          ref={seekInputRef}
        />
      </div>

      <Tooltip
        id="timeTooltip"
        className={styles.tooltip}
        anchorId="hovered-time"
        place="top"
        offset={0}
        position={calculateTooltipYPosition()}
      >
        <span>{hoveredTime}</span>
      </Tooltip>
    </>
  );
};

export default VideoSeek;
