import { FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
// Types
import { GetMediaPost_getMediaPost_ImagePost_mentions } from 'api/mediaPost/types/GetMediaPost';
import { GetStream_stream_mentions } from 'api/streams/types/GetStream';
import { TargetType } from 'api/graphql-global-types';
// Styles
import styles from './MentionsView.module.scss';

type MentionsViewProps = {
  mentions:
    | GetMediaPost_getMediaPost_ImagePost_mentions[]
    | GetStream_stream_mentions[];
  className?: string;
};

const MentionsView: FC<MentionsViewProps> = ({ mentions, className }) => {
  // Get the first five mentions after sorting REGISTERED mentions first
  const firstFiveMentions = [...mentions]
    .sort((a) => (a.targetType === TargetType.REGISTERED ? -1 : 1))
    .slice(0, 5);

  const getLabel = (mention) => {
    const unregisteredContact = mention?.unregisteredContact;

    return unregisteredContact
      ? unregisteredContact.name
      : mention?.store?.storeDetails?.storeName || mention.name || '';
  };

  return (
    <div className={cn(styles.mentionList, className)}>
      {!!firstFiveMentions.length && !isMobile && (
        <p className={styles.mentionLabel}>Notable mentions:</p>
      )}
      <div className={styles.mentionViewItems}>
        {firstFiveMentions.map((mention) => (
          <div
            key={mention.id}
            className={cn(styles.mentionItem, {
              [styles.darkTheme]:
                mention.targetType === TargetType.UNREGISTERED,
            })}
          >
            {mention.targetType === TargetType.REGISTERED &&
            mention.store?.slug ? (
              <Link href={`/${mention.store.slug}`}>
                <a className={styles.linkWrapper}>
                  <img
                    src={
                      mention.store?.storeDetails?.avatarURL || '/favicon.png'
                    }
                    alt="mention avatar"
                    className={styles.imageAvatar}
                  />
                  <p className={styles.mentionTitle}>{getLabel(mention)}</p>
                </a>
              </Link>
            ) : (
              <>
                {mention.unregisteredContact?.url ? (
                  <Link
                    href={
                      mention.unregisteredContact.url.startsWith('http')
                        ? mention.unregisteredContact.url
                        : `https://${mention.unregisteredContact.url}`
                    }
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.linkWrapper}
                    >
                      <img
                        src={
                          mention.store?.storeDetails?.avatarURL ||
                          '/favicon.png'
                        }
                        alt="mention avatar"
                        className={cn(styles.imageAvatar, {
                          [styles.hideAvatarImage]:
                            mention.targetType === TargetType.UNREGISTERED,
                        })}
                      />
                      <p className={styles.mentionTitle}>{getLabel(mention)}</p>
                    </a>
                  </Link>
                ) : (
                  <div className={styles.disabledLink}>
                    <img
                      src={
                        mention.store?.storeDetails?.avatarURL || '/favicon.png'
                      }
                      alt="mention avatar"
                      className={cn(styles.imageAvatar, {
                        [styles.hideAvatarImage]:
                          mention.targetType === TargetType.UNREGISTERED,
                      })}
                    />
                    <p className={styles.mentionTitle}>{getLabel(mention)}</p>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MentionsView;
